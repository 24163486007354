/**
 * A component used to display a title and content, typically used for section headers or introductory text.
 *
 * @component
 * @param {(string|JSX.Element)} [props.title] - The title of the content block
 * @param {string} [props.content] - The main content text
 * @param {Object} [props.titleStyles] - Custom styles for the title
 * @param {Object} [props.contentStyles] - Custom styles for the content
 *
 * @example
 * <ContentBlock
 *   title="Section Title"
 *   content="This is the main content of the section."
 *   titleStyles={{ fontSize: "24px" }}
 *   contentStyles={{ lineHeight: 1.6 }}
 * />
 */

import React, { PropsWithChildren } from 'react'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

interface ContentBlockProps {
	title?: string | JSX.Element
	content?: string
	titleStyles?: object
	contentStyles?: object
}

const ContentBlock: React.FC<PropsWithChildren<ContentBlockProps>> = ({ title, content, titleStyles, contentStyles }) => {
	const theme = useTheme()

	return (
		<>
			<Typography
				variant="h2"
				sx={{
					mb: 2,
					color: theme.palette.mode === 'dark' ? theme.palette.custom.light : 'black',
					...titleStyles
				}}
			>
				{title}
			</Typography>
			<Typography
				variant="body1"
				sx={{
					color: theme.palette.mode === 'dark' ? theme.palette.custom.light : 'black',
					...contentStyles
				}}
			>
				{content}
			</Typography>
		</>
	)
}

export default ContentBlock
