import { lazy, Suspense } from 'react'

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import Head from 'next/head'

const Header = lazy(() => import('./header'))
const FooterBlock = lazy(() => import('./FooterBlock'))

const LoaderWrapper = styled('div')(({ theme }) => ({
	position: 'fixed',
	top: 0,
	left: 0,
	zIndex: 2001,
	width: '100%',
	'& > * + *': {
		marginTop: theme.spacing(2)
	}
}))

export type LoaderProps = LinearProgressProps

const Loader = () => (
	<LoaderWrapper>
		<LinearProgress color="primary" />
	</LoaderWrapper>
)

interface Props {
	title?: string
	isLoggedIn?: boolean
}

export default function Layout({ children, title, isLoggedIn = false }: React.PropsWithChildren<Props>) {
	const fullTitle = title ? `ASTA | ${title}` : 'ASTA'
	// todo: create a better description
	const description = 'ASTA: ASTA performs fully autonomous functional testing of enterprise applications.'
	return (
		<Suspense fallback={<Loader />}>
			<Head>
				<title>{fullTitle}</title>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="ASTA, Testing, Autonomous, Software Testing Agent"></meta>
				<meta name="description" content={description} />
				<meta name="og:sitename" content="ASTA" />
				<meta name="og:site_name" content="ASTA" />
				<meta name="og:title" content={fullTitle} />
				<meta name="og:description" content={description} />
				<meta name="twitter:title" content={fullTitle} />
			</Head>
			<Header isLoggedIn={isLoggedIn} />
			{children}
			<FooterBlock />
		</Suspense>
	)
}
