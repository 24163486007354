import { useContext } from 'react'

import { SelectOutlined } from '@ant-design/icons'
import { Box, Button, CardMedia, Container, Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AnimateButton from 'components/@extended/AnimateButton'
import { AuthContext } from 'contexts/auth'

const FreeTrialBlock = () => {
	const theme = useTheme()

	const { logIn } = useContext(AuthContext)

	return (
		<Box
			component="section"
			sx={{
				position: 'relative',
				overflow: 'hidden',
				//@ts-ignore
				bgcolor: theme.palette.secondary[800],
				'&:after': {
					content: '""',
					position: 'absolute',
					width: '100%',
					height: '80%',
					bottom: 0,
					left: 0,
					//@ts-ignore
					background: `linear-gradient(180deg, transparent, ${theme.palette.secondary[800]})`
				}
			}}
		>
			<CardMedia sx={{ width: 'auto', position: 'absolute', top: 0, right: 0 }}>
				<img
					src="assets/images/landing/img-bg-screen.png"
					alt="bg-free-trial-image"
					width={625}
					height={872}
					style={{ objectFit: 'cover' }}
				/>
			</CardMedia>
			<Container>
				<Box
					sx={{
						position: 'relative',
						zIndex: 1,
						pt: { md: 18.75, xs: 7.5 },
						pb: { md: 10, xs: 3.75 },
						display: 'flex',
						flexDirection: 'column',
						textAlign: {
							md: 'start',
							xs: 'center'
						},
						gap: 2
					}}
				>
					<Typography
						variant="h2"
						sx={{
							color: theme.palette.common.white,
							fontSize: {
								xs: '1.25rem',
								sm: '1.56rem',
								md: '1.875rem'
							},
							fontWeight: 700,
							lineHeight: { xs: 1.4, sm: 1.4, md: 1.4 }
						}}
					>
						<Box component="span" sx={{ mx: 0 }}>
							Sign-up for a free trial!
						</Box>
					</Typography>
					<AnimateButton>
						<Button
							component={Link}
							size="large"
							color="primary"
							variant="contained"
							startIcon={<SelectOutlined />}
							onClick={() => logIn()}
							sx={{ my: 3.5 }}
						>
							Sign-up Now
						</Button>
					</AnimateButton>
				</Box>
			</Container>
		</Box>
	)
}

export default FreeTrialBlock
